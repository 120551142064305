import React from "react";
import "./Info.scss";
import img from "../../assets/Sugar-Rush.png";
import img2 from "../../assets/Sugar-Rush-Win.png";
import img3 from "../../assets/Sugar-Rush-How-to-Play.png";
import img4 from "../../assets/Sugar-Rush-Symbols.png";
import img5 from "../../assets/Sugar-Rush-Free-Spins.png";
import img6 from "../../assets/Sugar-Rush-Bonus.png";
import img7 from "../../assets/Sugar-Rush-Autoplay.png";
import arrow from "../../assets/down-arrow-svgrepo-com.svg";

const Info = () => {
  return (
    <div>
      <div className="title-box center">
        <h1>
          Review of Sugar Rush Slot by Pragmatic Play: Sweet Possibilities? Icon
          Family Classic Select an Icon Style
        </h1>
      </div>
      <div className="text-box center">
        <p>
          Pragmatic Play has been a renowned name in the gaming industry,
          predominantly for its uncanny ability to fuse themes with unparalleled
          gameplay mechanics. And when it comes to concocting a treat for the
          senses, perhaps nothing stands out quite as much as their Sugar Rush
          slot.
        </p>
      </div>
      <div className="button-box center">
        <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="text-box center">
        <p>
          Set amidst a backdrop of sugary landscapes, Sugar Rush Slot by
          Pragmatic Play is not just visually appetizing but also a treat for
          those seeking generous payouts. The game's captivating graphics are
          intertwined with a symphony of sweet tunes, ensuring a delightful
          gaming experience for all players.
        </p>
        <div className="img-box">
          <img src={img} alt="" />
        </div>
      </div>
      <div className="table-box center">
        <table className="table">
          <thead>
            <tr>
              <th>Game name</th>
              <th>Sugar Rush</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>🎰 Provider</td>
              <td>Pragmatic Play</td>
            </tr>
            <tr>
              <td>🎲 RTP (Return To Player)</td>
              <td>96.52%</td>
            </tr>
            <tr>
              <td>📉 Min bet $, €, £:</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>📈 Max bet $, €, £:</td>
              <td>100</td>
            </tr>
            <tr>
              <td>🤑 Maximum Win</td>
              <td>5,000x bet</td>
            </tr>
            <tr>
              <td>📱Compatible Devices</td>
              <td>Mobile, Desktop, Tablet</td>
            </tr>
            <tr>
              <td>📅 Release Date</td>
              <td>16.06.2022</td>
            </tr>
            <tr>
              <td>📞 Support</td>
              <td>24/7 via chat and email</td>
            </tr>
            <tr>
              <td>🚀 Game type</td>
              <td>Video Slot</td>
            </tr>
            <tr>
              <td>⚡ Volatility</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>🔥 Popularity</td>
              <td>4/5</td>
            </tr>
            <tr>
              <td>🎨 Visual Effects</td>
              <td>5/5</td>
            </tr>
            <tr>
              <td>👥 Customer Support</td>
              <td>4/5</td>
            </tr>
            <tr>
              <td>🔒 Security</td>
              <td>5/5</td>
            </tr>
            <tr>
              <td>🧹 Theme</td>
              <td>Confectionary Wonderland</td>
            </tr>
            <tr>
              <td>🎮 Available Demo Game</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>🎁 Bonus Features</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="title-box center">
        <h1>Unwrapping the Sugar Rush Slot Gameplay</h1>
      </div>
      <div className="text-box center">
        <p>
          The Sugar Rush slot stands out for its innovative design that captures
          the imagination. Set in a whimsical candyland environment, the slot
          presents a tantalizing 7x7 grid, seemingly encased in a vibrant candy
          machine. A cascade of colorful, wobbling gel symbols dominate the
          grid, reminiscent of candies that once adorned grandma's candy jar.
        </p>
        <p>
          The game is replete with unique features. Winning symbols, for
          instance, leave behind luminous spots. These aren't just any spots,
          though. They metamorphose into multiplier positions, each multiplying
          in value with each new win. These multiplier spots, which potentially
          commence at x2, could soar to a whopping x128, making it a pivotal
          aspect for those aiming for the grand wins.
        </p>
      </div>
      <div className="img-box">
        <img src={img2} alt="" />
      </div>
      <div className="title-box center">
        <h1>How to Play Sugar Rush in Detail</h1>
      </div>
      <div className="text-box center">
        <p>
          Engage with Sugar Rush by first selecting your bet per line. There are
          20 fixed paylines to play on. Once satisfied, press the spin button.
          Align three or more identical symbols on a payline to win. The game
          also features an autoplay function, enabling a set number of
          uninterrupted spins.
        </p>
      </div>
      <div className="button-box center">
       <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="img-box">
        <img src={img3} alt="" />
      </div>
      <div className="title-box center">
        <h1>Sugar Rush Features:</h1>
      </div>
      <div className="check-box center">
        <ul className="check-ul">
          <li className="check-item">
            <strong>Unique Winning Mechanism:</strong>The goal is
            straightforward - land between 5 to 15 or more matching symbols in a
            cluster on the 7x7 grid. The more premium symbols you match, the
            higher the stakes, with a 15+ matching premium symbols resulting in
            a win of 40 to 150 times your stake.{" "}
          </li>
          <li>
            <strong>Tumble Win Dynamics:</strong> Winning symbols vanish to be
            replaced by new ones cascading from the top. This tumble continues
            as long as you keep amassing wins. Interestingly, wild symbols,
            which are typically a staple in such games, are conspicuously absent
            here.
          </li>
          <li>
            <strong>Multiplier Spots Feature:</strong> As mentioned, winning
            symbols leave a glowing spot. If another winning symbol replaces the
            previous one in the highlighted spot, a multiplier, starting
            typically at x2, appears. With every subsequent winning symbol
            removed above it, this multiplier doubles.
          </li>
          <li>
            <strong>Bonus Rounds Galore:</strong>Bonus rounds can be activated
            by landing 3 to 7 scatters during the same tumble win sequence. This
            can earn players anywhere between 10 to 30 free spins. What
            distinguishes the Sugar Rush bonus round is that multipliers and
            highlighted spots persist, which might heighten the chances of
            accumulating robust multipliers.
          </li>
        </ul>
      </div>
      <div className="img-box">
        <img src={img4} alt="" />
      </div>
      <div className="text-box center">
        <p>
          For those with a penchant for instant gratification, there's the Bonus
          Buy button. While not available everywhere, it lets players buy into
          the bonus round. It's a gamble, but it's one that offers 3 to 7
          triggering scatters on the ensuing spin, albeit at a cost.
        </p>
      </div>
      <div className="button-box center">
       <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="img-box">
        <img src={img5} alt="" />
      </div>
      <div className="title-box center">
        <h1>Pros and Cons of Sugar Rush Game</h1>
      </div>
      <div className="pros-cons center">
        <div className="pros">
          <strong className="strong">Pros:</strong>
          <p></p>
          <li>Stunning graphics and animations</li>
          <li>Engaging bonus features</li>
          <li>High RTP</li>
        </div>
        <div className="cons">
          <strong>Cons:</strong>
          <p></p>
          <li>No progressive jackpot</li>
          <li>Limited paylines</li>
        </div>
      </div>
      <div className="title-box center">
        <h1>Available Sugar Rush Game Platforms</h1>
      </div>
      <div className="text-box center">
        <p>
          A sign of a great slot is its versatility, and Sugar Rush delivers.
          Players can indulge in this sweet slot across a variety of platforms
          including desktops, mobile devices (iOS and Android), and tablets. The
          transition across devices is seamless, ensuring the visual fidelity
          and gameplay remain consistent.
        </p>
      </div>
      <div className="infra center">
        <div>
          <p>Start</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>Land 5 to 15+ matching symbols</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>Winning symbols replaced with new ones from above</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>Spot multipliers feature activated</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>Land 3 to 7 scatters in the same tumble win</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>10 to 30 free spins in Bonus Round</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>Keep winning with persistent multipliers</p>
          <img src={arrow} alt="" />
        </div>
        <div>
          <p>End</p>
        </div>
      </div>
      <div className="button-box center">
       <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="title-box center">
        <h1>Sugar Rush Demo Play Version</h1>
      </div>
      <div className="text-box center">
        <p>
          If you're not quite ready to commit your coins, the demo version of
          Sugar Rush is a boon. This free play version lets players immerse in
          all the game's features without the risk. It's a perfect avenue to get
          a feel for the game mechanics and bonus rounds before venturing into
          real-money play.
        </p>
      </div>
      <div className="img-box">
        <img src={img6} alt="" />
      </div>
      <div className="title-box center">
        <h1>How to Sign Up to Play Sugar Rush</h1>
      </div>
      <div className="text-box center">
        <p>
          To dive into this candy paradise, select a trustworthy online casino
          and navigate to the registration page. Once registered, search for
          Sugar Rush in the games library. Ensure you're playing at a licensed
          casino for a safe gaming experience.
        </p>
      </div>
      <div className="title-box center">
        <h1>Playing Sugar Rush for Real Money</h1>
      </div>
      <div className="text-box center">
        <p>
          After registration, navigate to the deposit section of the casino,
          select a suitable payment method, and fund your account. Then, head
          over to the game, adjust your bet sizes, and spin away, hoping for
          some sweet rewards.
        </p>
      </div>
      <div className="button-box center">
       <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="img-box">
        <img src={img7} alt="" />
      </div>
      <div className="title-box center">
        <h1>Deposits and Withdrawals for Sugar Rush</h1>
      </div>
      <div className="text-box center">
        <p>
          Online casinos generally offer a plethora of payment options,
          including credit cards, e-wallets, and bank transfers. To withdraw
          your wins from Sugar Rush, head over to the casino's withdrawal
          section, choose your preferred method, and follow the on-screen
          instructions.
        </p>
      </div>
      <div className="title-box center">
        <h1>Strategies, Tactics, and Tips</h1>
      </div>
      <div className="check-box center">
        <ul className="check-ul">
          <li className="check-item">
            Experiment with the game in demo mode first.
          </li>
          <li>Set a budget and stick to it.</li>
          <li>Maximize any casino bonuses or free spins offers.</li>
          <li>Familiarize yourself with the game's paytable.</li>
        </ul>
      </div>
      <div className="img-box">
        <iframe
          width="1170"
          height="700"
          src="https://www.youtube.com/embed/7qKMsGhTH98"
          title="Sugar Rush slot by Pragmatic Play - Gameplay"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="title-box center">
        <h1>Pragmatic Play Casino Game Provider: A Brief Overview</h1>
      </div>
      <div className="text-box center">
        <p>
          Pragmatic Play, while still relatively young in the vast landscape of
          iGaming, has tentatively made its mark as a beacon of innovation and
          quality. Their rapid ascension in the industry isn't just by chance;
          it's a testament to their dedication to crafting immersive gaming
          experiences for players worldwide. The diverse range of games,
          including slots, live casinos, and virtual sports, showcases their
          versatility.
        </p>
      </div>
      <div className="title-box center">
        <h1>Other Games by Pragmatic Play</h1>
      </div>
      <div className="check-box center">
        <ul className="check-ul">
          <li className="check-item">
            <strong>Fruit Party 2:</strong> A fruit-themed 7x7 cluster slot from
            Pragmatic Play, notable for its escalating multiplier wilds. It
            offers up to a 5,000x stake potential.
          </li>
          <li>
            <strong>Tasty Treats:</strong>A candy-themed game from Hacksaw
            Gaming, it includes a 6x5 cluster pay grid and cascading wins. The
            bonus round is particularly riveting, potentially offering up to
            10,000x your stake.
          </li>
          <li>
            <strong>Sweet Bonanza:</strong>A beloved Pragmatic Play offering
            that boasts a 6x5 cluster pay grid. With candy bomb multipliers
            reaching up to x100, players might eye the touted 21,000x maximum
            win.
          </li>
        </ul>
      </div>
      <div className="title-box center">
        <h1>Top 5 Real Casinos to Play Sugar Rush</h1>
      </div>
      <div className="check-box center">
        <ul className="num-ul center">
          <li>
            <strong>Gamble Galaxy:</strong>Up to 200 free spins on first
            deposit.
          </li>
          <li>
            <strong>Slots Villa: </strong>100% match bonus plus weekly cashback.
          </li>
          <li>
            <strong>Spin Palace:</strong>Earn loyalty points for every spin.
          </li>
          <li>
            <strong>Casino Dream:</strong>Regular slot tournaments with hefty
            prizes.
          </li>
          <li>
            <strong>Wager World:</strong>Exclusive VIP program with enhanced
            bonuses.
          </li>
        </ul>
      </div>
      <div className="title-box center">
        <h1>Player Reviews</h1>
      </div>
      <div className="quote center">
        <strong>CandyKing123:</strong>
        <div className="quote-text">
          <h2>
            <strong>
              This slot is a visual feast! And the payouts? So sweet!
            </strong>
          </h2>
        </div>
      </div>
      <div className="quote center">
        <strong>SpinSorceress:</strong>
        <div className="quote-text">
          <h2>
            <strong>
              Been playing for weeks and can't get enough. Those bonuses are
              something else!
            </strong>
          </h2>
        </div>
      </div>
      <div className="quote center">
        <strong>JackpotJoe::</strong>
        <div className="quote-text">
          <h2>
            <strong>
              One of Pragmatic Play's best! Had some of my biggest wins here.
            </strong>
          </h2>
        </div>
      </div>
      <div className="button-box center">
       <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
          <button className="button-24">Play Now!</button>
        </a>
      </div>
      <div className="title-box center">
        <h1>Conclusion</h1>
      </div>
      <div className="text-box center">
        <p>
          Navigating through the saccharine lanes of Sugar Rush by Pragmatic
          Play provides not just an avenue for potential wins but also an
          immensely enjoyable gaming journey. Its blend of engaging gameplay,
          delightful visuals, and generous bonus features ensures that this slot
          remains a must-play for enthusiasts. Whether you're seeking a quick
          spin or a prolonged play session, Sugar Rush promises an experience as
          enriching as its theme.
        </p>
      </div>
      <div className="title-box center">
        <h1>Frequently Asked Questions (FAQ)</h1>
      </div>
      <div className="reviews center">
        <div className="review-msg">
          <strong>What is the Sugar Rush slot?</strong>
          <p>
            The Sugar Rush slot is an online slot game developed by Pragmatic
            Play. It's set against a delightful backdrop of sugary landscapes,
            offering players an opportunity for generous payouts.
          </p>
        </div>
        <div className="review-msg">
          <strong>Who is the developer of the Sugar Rush game?</strong>
          <p>
            Sugar Rush has been masterfully crafted by Pragmatic Play, a
            renowned name in the world of online slots.
          </p>
        </div>
        <div className="review-msg">
          <strong>Where can I play Sugar Rush for free?</strong>
          <p>
            You can experience the Sugar Rush demo on various online casino
            platforms. This free play mode allows players to get a feel for the
            game without any monetary commitments.
          </p>
        </div>
        <div className="review-msg">
          <strong>Is there a demo version of the Sugar Rush slot?</strong>
          <p>
            Yes, the Sugar Rush demo is available across several online casinos.
            It's perfect for those who want to play and understand the game
            mechanics before venturing into real-money play.
          </p>
        </div>
        <div className="review-msg">
          <strong>How can I play Sugar Rush online for free?</strong>
          <p>
            You can play the Sugar Rush game online free by selecting its demo
            version on a chosen casino platform. No deposit or sign-up is
            usually required for free play.
          </p>
        </div>
        <div className="review-msg">
          <strong>What is the RTP of the Sugar Rush slot?</strong>
          <p>
            The Sugar Rush slot has an RTP (Return to Player) of 96.52%, the
            slot leans towards being a medium variance game. This suggests that
            players might experience fairly consistent wins, but with the
            possibility of bigger wins thrown in occasionally.
          </p>
        </div>
        <div className="review-msg">
          <strong>How can I achieve the Sugar Rush max win?</strong>
          <p>
            The maximum win in Sugar Rush can be achieved by triggering specific
            bonus rounds and landing the right combination of symbols on the
            reels.
          </p>
        </div>
        <div className="review-msg">
          <strong>Can I get free spins in the Sugar Rush game?</strong>
          <p>
            Absolutely! Sugar Rush has a free spins bonus feature which can be
            activated by securing a specific number of scatter symbols.
          </p>
        </div>
        <div className="review-msg">
          <strong>What makes the Sugar Rush slot stand out?</strong>
          <p>
            Many players often cite the game's captivating graphics, rewarding
            bonus features, and overall enjoyable experience in their Sugar Rush
            slot review.
          </p>
        </div>
        <div className="review-msg">
          <strong>Is Sugar Rush gambling safe?</strong>
          <p>
            Safety while playing Sugar Rush or any online slot largely depends
            on the casino you choose. Always ensure you're playing at a licensed
            and regulated casino to ensure a secure gambling environment.
          </p>
        </div>
        <div className="review-msg">
          <strong>How many combinations can I get in Sugar Rush?</strong>
          <p>
            There are numerous symbol combinations in the Sugar Rush slot that
            can result in wins. Familiarizing yourself with the game's paytable
            can provide insights into possible winning combinations.
          </p>
        </div>
        <div className="review-msg">
          <strong>
            Do I need to download any software to play Sugar Rush online?
          </strong>
          <p>
            No, most modern online casinos offer the Sugarrush online slot in an
            instant-play format. This means you can play directly from your
            browser without needing any downloads.
          </p>
        </div>
        <div className="review-msg">
          <strong>
            I want to play Sugar Rush for real money. Where should I start?
          </strong>
          <p>
            If you want to play Sugar Rush for real money, begin by selecting a
            reputable online casino, register, make a deposit, and then search
            for the game in the casino's game library.
          </p>
        </div>
        <div className="review-msg">
          <strong>
            Why is the Sugar Rush slot review positive on many platforms?
          </strong>
          <p>
            The positive Sugar Rush slot review on many platforms can be
            attributed to its immersive graphics, engaging gameplay, and
            generous bonus features crafted by Pragmatic Play.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
