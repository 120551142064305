import { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer">
        <div className="footer-cont center">
          <div className="text-copy">
            <p>© Copyright {currentYear} Sugar Rush Slot</p>
          </div>
          <div className="text-link">
            <a>🍭Sugar Rush by Pragmatic Play🍭</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
