import React from 'react'
import "./Header.scss"

const Header = () => {
  return (
    <header className='header'>
      <div className='header-in center'>
        <div className='logo-img'>
          <a href="/">
            <img src="https://playsugarrush.com/wp-content/uploads/2023/09/cropped-Sugar-Rush-Logo-40x40.png" alt="" />
          </a>
        </div>
        <div className='text-but'>
          <a href="/">🍭Sugar Rush by Pragmatic Play🍭</a>
        </div>
    </div>
    </header>
  )
}

export default Header