import React from "react";
import Header from "../components/header/Header";
import Info from "../components/info/Info";
import Footer from "../components/footer/Footer";
<link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'></link>

const HomePage = () => {
  return (
    <>
    <Header/>
    <Info/>
    <Footer/>
    </>
  );
};

export default HomePage;